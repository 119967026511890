import React, { useEffect } from "react";
import "./styles.css";
import { baseUrl } from "../../../services/config";
import { useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Accordion, Card, Modal, Spinner } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  DatePicker,
} from "@material-ui/pickers";
import Select from "react-select";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Paper, TextField } from "@material-ui/core";
import { DatePicker as antDatePicker } from "antd";
import { Dropdown } from "react-bootstrap";
import {
  DropdownCustomToggler,
  DropdownMenu1,
} from "../../../_metronic/_partials/dropdowns";
import { StatusDropdown } from "./StatusDropdown/StatusDropdown";
import { useSelector } from "react-redux";
import { PremissionChecker } from "../../../services/PremissionChecker";
import { FaRegEdit } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import { HiOutlineSun } from "react-icons/hi2";
import { GoPencil } from "react-icons/go";
import { RiDeleteBinLine } from "react-icons/ri";
import "./accordian.css";
// import sub from "date-fns/esm/sub/index";

const ViewHolidays = () => {
  const [getstatus, setGetStatus] = useState([]);
  const [status, setStatus] = useState();
  const [buttonLoading, setbuttonLoading] = useState(false);
  let location = useLocation();
  let params = useParams();
  const authtoken = localStorage.getItem("userToken");
  // console.log(location?.state?.state?.Obj)
  const localizer = momentLocalizer(moment);
  const [filterMonth, setfilterMonth] = useState("");
  const [filterYear, setfilterYear] = useState("");
  const [selectedUser, setSelectedUser] = useState();
  const [userList, setUserList] = useState([]);
  const [editModel, setEditModel] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [carriedForward, setCarriedForward] = useState("");
  const [basicEntitle, setBasicEntitle] = useState("");
  const [adjustment, setAdjustment] = useState("");
  const [workingHours, setWorkingHours] = useState("");
  const [postAdjustment, setPostAdjustment] = useState("");
  const [postWeeklyHours, setPostWeeklyHours] = useState("");
  const [postStaffEntitlement, setPostStaffEntitlement] = useState("");
  const [getLeavesLoading, setGetLeavesLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [yearArray, setYearArray] = useState([]);
  const [yearFilter, setYearFilter] = useState();
  useEffect(() => {
    getStatus();
  }, []);

  const { RangePicker } = antDatePicker;

  const [value, setValue] = useState();

  const { id } = params;
  // const { Obj } = location.state.state

  const user = useSelector((state) => state.user.user);

  const [leaveDetail, setleaveDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const myUser = location.state;

  const [myCurrentUser, setMyCurrentUser] = useState({});

  const [userLeaveApplications, setUserLeaveApplications] = useState([]);

  const [remaingLeaves, setRemainingLeaves] = useState();
  const [apiRemaningLeaves, setApiRemaningLeaves] = useState("");
  const [postCarried, setPostCarried] = useState("");
  const [approvedWorkingDays,setApprovedWorkingDays]=useState(0)
  console.log(myUser, "here we go");

  useEffect(() => {
    getUsersList();
    // getTotalLeaves()
    getLeavesApplications(myUser.id);
    getLeaves(new Date().getFullYear(), myUser.id);
    setMyCurrentUser({ label: `${myUser.first_name} ${myUser.last_name}`, value: myUser.id, ...myUser });
  }, []);

  const getLeavesApplications = (id) => {
    setUserLeaveApplications([]);
    setLoading(true);
    fetch(`${baseUrl}/mgmt/user-leave-applications-details/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            if (data.payload.leave_applications.length > 0) {
              console.log(data.payload);
              const arr = data.payload.leave_applications;
              const remain = data.payload.remaining_leaves;
              const groupedByYear = arr.reduce((acc, obj) => {
                const year = obj.start_date.substring(6, 8);
                if (!acc[year]) {
                  acc[year] = {
                    data: [],
                    counts: { APPROVED: 0, REJECTED: 0, PENDING: 0 },
                  };
                }
                acc[year].data.push(obj);
                acc[year].counts[obj.status]++;
                return acc;
              }, {});

              const arr2 = Object.entries(groupedByYear).map(
                ([year, { data, counts }]) => ({
                  year: "20" + year,
                  data,
                  counts,
                })
              );
              const arr3 = arr2.sort((a, b) => b.year - a.year);
              const arr4 = remain.sort((a, b) => b.year - a.year);
              arr3.forEach((item) => {
                arr4.map((subitem) => {
                  if (item.year == subitem.year) {
                    item["remaning"] = subitem;
                  }
                });
              });
              console.log(arr3, "finnaly i don it=>>>>>>>");

              const finnal = convertPayload(arr3);
              console.log(finnal, "finnaly i don it=>>>>>>>>>>>>>>>>");
              setUserLeaveApplications(finnal);

          
              setLoading(false);
            } else {
              setUserLeaveApplications([]);
            }
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  function convertPayload(payload) {
    return payload.map((item) => {
      const leaveCounts = {};
      const data = item.data;

      // Count occurrences of each leave type
      data.forEach((entry) => {
        const leaveType = entry.type.name;
        if (entry.status == "APPROVED") {
          if (leaveCounts[leaveType]) {
            leaveCounts[leaveType]++;
          } else {
            leaveCounts[leaveType] = 1;
          }
        }
      });

      // Format types array
      const types = Object.keys(leaveCounts).map((key) => {
        const obj = {};
        obj[key] = leaveCounts[key];
        return obj;
      });

      // Return the formatted object
      return {
        types: types,
        ...item,
      };
    });
  }

  const getUsersList = () => {
    fetch(`${baseUrl}/mgmt/users`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let arr = [];
        data.payload.map((item) => {
          arr.push({ label: `${item.first_name} ${item.last_name}`, value: item.id, ...item });
        });
        setUserList(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStatus = () => {
    fetch(`${baseUrl}/asset/leave-application-statuses`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setGetStatus(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataCoverter = (arr) => {
    const grouped = arr.reduce((acc, item) => {
      const typeName = item.type.name;
      if (!acc[typeName]) {
        acc[typeName] = {
          name: typeName,
          allApplications: [],
        };
      }
      acc[typeName].allApplications.push(item);
      return acc;
    }, {});

    const arr2 = Object.values(grouped);
    return arr2;
  };
  const dataSearch2 = userLeaveApplications
    .map((main) => {
      if (yearFilter.value !== "" && main.year == yearFilter.value) {
        const filteredData = main.data?.filter((item) => {
          // if (filterType.value !== "") {
          //   return item.type.name === filterType.value;
          // } else {
          return true;
          // }
        });
        const resultant = dataCoverter(filteredData);
        return { ...main, data: resultant };
      } else if (yearFilter.value === "") {
        const filteredData = main.data?.filter((item) => {
          // if (filterType.value !== "") {
          //   return item.type.name === filterType.value;
          // } else {
          return true;
          // }
        });
        const resultant = dataCoverter(filteredData);

        return { ...main, data: resultant };
      } else {
        return undefined;
      }
    })
    .filter((item) => item !== undefined);

  const getLeaves = async (year, id) => {
    // setGetLeavesLoading(true);
    await fetch(`${baseUrl}/mgmt/user/${id}/remaining-leaves/${year}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // setGetLeavesLoading(false);
        if (data.metadata) {
          if (data.metadata.success) {
            setRemainingLeaves(data?.payload?.remaining_leave_calculator);
            setPostAdjustment(
              data?.payload?.remaining_leave_calculator?.adjustment_leaves
            );
            setPostWeeklyHours(data?.payload?.user_work_pattern_hours);
            setPostStaffEntitlement(
              data?.payload?.remaining_leave_calculator
                ?.full_time_staff_entitlement
            );
            setPostCarried(
              data?.payload?.remaining_leave_calculator?.rollback_leaves
            );
          }
        }
      })
      .catch((err) => {
        // setGetLeavesLoading(false);
        console.log("vehicle", err);
      });
  };
  const getLeaves2 = async (year, id) => {
    setGetLeavesLoading(true);
    await fetch(`${baseUrl}/mgmt/user/${id}/remaining-leaves/${year}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setRemainingLeaves(data?.payload?.remaining_leave_calculator);
            setPostAdjustment(
              data?.payload?.remaining_leave_calculator?.adjustment_leaves
            );
            setPostWeeklyHours(data?.payload?.user_work_pattern_hours);
            // setPostStaffEntitlement(
            //   data?.payload?.remaining_leave_calculator
            //     ?.full_time_staff_entitlement
            // );
            setPostCarried(
              data?.payload?.remaining_leave_calculator?.rollback_leaves
            );
            getEntitlement();
          }
        }
      })
      .catch((err) => {
        setGetLeavesLoading(false);
        console.log("vehicle", err);
      });
  };
  const dateFormatChanger = (inputDate) => {
    const parts = inputDate?.split("-");
    const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
    return formattedDate;
  };
  const gussTheCount = (myArray) => {
    let res = myArray.filter((item) => item.working_day == "1");
    return res.length;
  };
  // console.log(location?.state?.state?.Obj)

  const openEditModal = () => {
    setEditModel(true);
  };

  const updateHolidays = () => {
    console.log(postCarried, "hdhsfhsdhf");
    if (
      postAdjustment === "" ||
      postAdjustment === null ||
      postAdjustment === undefined ||
      postWeeklyHours === "" ||
      postWeeklyHours === null ||
      postWeeklyHours === undefined ||
      postStaffEntitlement === "" ||
      postStaffEntitlement === null ||
      postStaffEntitlement === undefined ||
      postCarried === "" ||
      postCarried === null ||
      postCarried === undefined
    ) {
      return toast.error("Please enter all fields");
    }

    setPostLoading(true);
    const data = {
      adjustment_leaves: postAdjustment,
      full_time_staff_entitlement: postStaffEntitlement,
      user_work_pattern_hours: postWeeklyHours,
      rollback_leaves: postCarried,
    };
    // fetch(`${baseUrl}/mgmt/update/${myUser.id}/adjustment-leaves/${apiRemaningLeaves}`, {
    fetch(
      `${baseUrl}/mgmt/update/${myCurrentUser.id}/adjustment-leaves/${yearFilter.value}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setPostLoading(false);
        if (data.metadata.success) {
          toast.success("Remaining Leaves updated successfully");
          // getTotalLeaves()
          getLeavesApplications(myCurrentUser.id);
          getLeaves(yearFilter.value, myCurrentUser.id);
          setEditModel(false);
        } else if (!data.metadata.success) {
          if (toString.call(data.metadata.message) == "[object Object]") {
            const errorMessageArray = Object.values(
              data.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => {
              toast.error(message);
            });
          } else if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error(data.metadata.message);
          }
        } else {
          toast.error("Unknown error :(");
        }
      })
      .catch((err) => {
        setPostLoading(false);
        console.log(err);
      });
  };
  // console.log(dataSearch2,"datasearch")

  function lightenColor(color, percent) {
    // Convert HEX to RGB
    color = color.substring(1); // Remove #
    var num = parseInt(color, 16);
    var r = (num >> 16) + percent;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + percent;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + percent;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    // Convert RGB to HEX
    return "#" + (g | (b << 8) | (r << 16)).toString(16).padStart(6, "0");
  }

  function formatDateRange(startDate, endDate) {
    // Helper function to get the day of the week abbreviation
    function getDayAbbreviation(date) {
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      return days[date.getDay()];
    }

    // Helper function to get the month abbreviation
    function getMonthAbbreviation(date) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return months[date.getMonth()];
    }

    // Helper function to parse date in DD/MM/YY format
    function parseDate(dateString) {
      const parts = dateString.split("/");
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // Months are zero-based in JavaScript
      const year = 2000 + parseInt(parts[2], 10); // Assuming 20XX for YY format
      return new Date(year, month, day);
    }

    const start = parseDate(startDate);
    const end = parseDate(endDate);

    // Format the start and end dates
    const startDay = getDayAbbreviation(start);
    const startDateFormatted = `${startDay} ${start.getDate()} ${getMonthAbbreviation(
      start
    )}`;

    const endDay = getDayAbbreviation(end);
    const endDateFormatted = `${endDay} ${end.getDate()} ${getMonthAbbreviation(
      end
    )} ${end.getFullYear()}`;

    return `${startDateFormatted} - ${endDateFormatted}`;
  }
  console.log(remaingLeaves?.hard_coded_total, "is getting");
  const CustomerStatusCssClasses = ["info", "success", "danger", ""];
  function convertDateFormat(dateString) {
    // Split the input date string into parts
    const [day, month, year] = dateString.split("/");

    // Create a new date object
    const date = new Date(`20${year}-${month}-${day}`);

    // Array of weekday and month names
    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Format the date
    const formattedDate = `${weekdays[date.getUTCDay()]} ${String(
      date.getUTCDate()
    ).padStart(2, "0")} ${months[date.getUTCMonth()]} ${date.getUTCFullYear()}`;

    return formattedDate;
  }
  // const yearArray = [
  //   {
  //     label: "01 Jan 2022 - 31 Dec 2022",
  //     value: "2022",
  //   },
  //   {
  //     label: "01 Jan 2023 - 31 Dec 2023",
  //     value: "2023",
  //   },
  //   {
  //     label: "01 Jan 2024 - 31 Dec 2024",
  //     value: "2024",
  //   },
  //   {
  //     label: "01 Jan 2025 - 31 Dec 2025",
  //     value: "2025",
  //   },
  //   {
  //     label: "01 Jan 2026 - 31 Dec 2026",
  //     value: "2026",
  //   },
  // ];

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 2022;
    const endYear = currentYear + 1;

    const yearArray = [];

    for (let year = startYear; year <= endYear; year++) {
      yearArray.push({
        label: `01 Jan ${year} - 31 Dec ${year}`,
        value: `${year}`,
      });
    }
    const resultant = yearArray.find((yy) => yy.value == currentYear);
    setYearFilter(resultant);
    setYearArray(yearArray);
  }, []);

  const getEntitlement = () => {
    setGetLeavesLoading(true);
    fetch(`${baseUrl}/mgmt/leave-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        setGetLeavesLoading(false);
        console.log("List", data.payload);
        if (data.metadata.success) {
          if (data.payload) {
            // setPostWeeklyHours(data?.payload?.full_time_working_hours);
            setPostStaffEntitlement(data?.payload?.full_time_staff_entitlement);
          }
        }
      })
      .catch((err) => {
        setGetLeavesLoading(false);

        console.log(err);
      });
  };
  console.log(yearArray);

  console.log(dataSearch2, "here we go");

  const givingWorkingDays = (array) => {
    const response = array.filter((item) => item.working_day == 1);
    if (response.length == 0 || response.length == 1) {
      return response.length + " Day";
    } else {
      return response.length + " Days";
    }
  };

  // function ymdToDmy(myDate) {
  //   if(myDate){
  //     const date=new Date(myDate)
  //       const day = String(date.getDate()).padStart(2, '0');
  //       const month = String(date.getMonth() + 1).padStart(2, '0'); 
  //       const year = date.getFullYear();
      
  //       const dmyDate = `${day}-${month}-${year}`;
  //       return dmyDate;
  //   }
  // }
  
const getApprovedLeaves=(arr)=>{
  
if(arr?.length>0){
  console.log(arr,"yummmyyy")

  
  let sum=0
    // arr.forEach(item => {
        // if (item && item.allApplications.length > 0) {
          arr.forEach(subItem => {
                if ( subItem.status =="APPROVED" && subItem && subItem.leaves && subItem.leaves.length > 0) {
                  if(subItem && subItem.leaves && subItem.leaves.length>0){
                    subItem.leaves.forEach(le => {
                      if (le.working_day == 1) {
                          sum += 1;
                      }
                  });
                  }
                  
                }
            });
        // }
    // });
    console.log(sum,"here we go with sun")
   return sum
}else{
  return 0

}
}
// console.log(myCurrentUser?.employment_end_date,"wejrhsajbhsa")
const dateConverter = (originalDateStr) => {
  if (originalDateStr) {
    const date = new Date(originalDateStr);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }
};
  return (
    <div>
      <Toaster />
      <div className="">
        <div
          className="row"
          style={{ paddingBottom: "20px", paddingTop: "10px" }}
        >
          <div className="col-lg-3">
            <label className="">
              <b>Users</b>
            </label>
            <Select
              value={myCurrentUser}
              className="basic-single"
              classNamePrefix="select"
              options={userList}
              onChange={(e) => {
                getLeavesApplications(e.id);
                getLeaves(yearFilter.value, e.id);
                setMyCurrentUser(e);
              }}
            />
          </div>

          <div className="col-lg-3">
            <label className="">
              <b>Leave Year</b>
            </label>
            <Select
              value={yearFilter}
              className="basic-single"
              classNamePrefix="select"
              options={yearArray}
              onChange={(e) => {
                setYearFilter(e);
                getLeaves(e.value, myCurrentUser.id);
              }}
            />
          </div>
        </div>
      </div>

      {loading ? (
        <div className="d-flex align-items-center justify-content-center mt-4">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <div className="row">
            <div
              className="col-md-8 m-0 rounded-top"
              style={{ backgroundColor: "#1e1e2d" }}
            >
              <div
                style={{ paddingTop: "20px", paddingBottom: "20px" }}
                className="col-md-12  d-flex justify-content-between"
              >
                <div className="font-size-h5 font-weight-bolder text-light ">
                  Leave Applications
                </div>
                <div className="d-flex">
                  <p className="projectNameAcc2">
                    Approved : {dataSearch2[0]?.counts.APPROVED}
                  </p>
                  <p className="projectNameAcc2">
                    Pending : {dataSearch2[0]?.counts.PENDING}
                  </p>
                  <p className="projectNameAcc2">
                    Rejected : {dataSearch2[0]?.counts.REJECTED}
                  </p>
                </div>
              </div>
            </div>

            <div></div>
          </div>
          <div className="card-body row p-0">
            <div
              className="col-md-8"
              style={{
                background: "white",
                padding: "10px 25px",
                border: "1px solid lightgray",
              }}
            >
              {dataSearch2[0]?.data?.length > 0 ? (
                dataSearch2[0]?.data.map((main, index) => (
                  <Accordion activeKey={activeAccordion}>
                    <div className="row">
                      <div className="" style={{ width: "100%" }}>
                        <Card className="mb-3">
                          <Card.Header className="custom-my-header">
                            <div className="d-flex ">
                              <Accordion.Toggle
                                className="myCustomBtns"
                                eventKey={index}
                                onClick={() => {
                                  setActiveAccordion(
                                    activeAccordion === index ? null : index
                                  );
                                }}
                              >
                                {activeAccordion === index ? (
                                  <MdKeyboardArrowUp className="opingClosing" />
                                ) : (
                                  <MdKeyboardArrowDown className="opingClosing" />
                                )}
                              </Accordion.Toggle>
                              <div
                                className="d-flex justify-content-between"
                                style={{ width: "100%" }}
                              >
                                <div className="d-flex">
                                  <p className="projectNameAcc">
                                    {main.name} (
                                    {main?.allApplications?.length || 0})
                                  </p>
                                  {/* <p className="projectNameAcc">
                                    Approved : {main?.counts.APPROVED}
                                  </p>
                                  <p className="projectNameAcc">
                                    Pending : {main?.counts.PENDING}
                                  </p>
                                  <p className="projectNameAcc">
                                    Rejected : {main?.counts.REJECTED}
                                  </p> */}
                                </div>
                                {/* <div>
                                  <p className="projectNameAcc">{main.year}</p>
                                </div> */}
                              </div>
                            </div>
                          </Card.Header>
                          <Accordion.Collapse eventKey={index}>
                            <div>
                              {main.allApplications &&
                                main.allApplications.map((item) => (
                                  <Card.Body className="my-custom-card-body">
                                    <div className="each-leave-card d-flex">
                                      <HiOutlineSun
                                        style={{ fontSize: "2rem" }}
                                      />
                                      <div className="leaves-detail-eact">
                                        <h6>{item?.type?.name}</h6>
                                        <h5>
                                          {formatDateRange(
                                            item.start_date,
                                            item.end_date
                                          )}{" "}
                                          ({givingWorkingDays(item?.leaves)})
                                        </h5>
                                        <p style={{ color: "##6b6c6e" }}>
                                          {item.note}
                                        </p>
                                        <div>
                                          <span
                                            className={`label status-leaves label-xl label-inline label-light-${
                                              CustomerStatusCssClasses[
                                                item.status == "PENDING"
                                                  ? 0
                                                  : item.status == "APPROVED"
                                                  ? 1
                                                  : 2
                                              ]
                                            }`}
                                          >
                                            {item.status}
                                          </span>
                                          <span
                                            className="mx-3"
                                            style={{ color: "#acadaf" }}
                                          >
                                            on{" "}
                                            {convertDateFormat(
                                              item?.applied_on
                                            )}{" "}
                                            by {myCurrentUser?.first_name}{" "}
                                            {myCurrentUser?.last_name}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="leaves-detail-eact-icons">
                                        {PremissionChecker(
                                          user,
                                          "leave_application.action"
                                        ) && !myCurrentUser?.employment_end_date ? (
                                          <div className="card-toolbar">
                                            <Dropdown
                                              className="dropdown-inline"
                                              alignRight
                                            >
                                              <Dropdown.Toggle
                                                id="dropdown-toggle-top"
                                                as={DropdownCustomToggler}
                                              >
                                                <GoPencil
                                                  style={{
                                                    color: "#427cb0",
                                                    fontSize: "1.5rem",
                                                  }}
                                                />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                                <StatusDropdown
                                                  statusList={getstatus}
                                                  userId={myCurrentUser.id}
                                                  leaveApps={
                                                    userLeaveApplications
                                                  }
                                                  updateStatus={(arr) =>
                                                    setUserLeaveApplications(
                                                      arr
                                                    )
                                                  }
                                                  reload={() => {
                                                    getLeavesApplications(
                                                      myCurrentUser.id
                                                    );
                                                    getLeaves(yearFilter.value, myCurrentUser.id);
                                                  }}
                                                  item={item}
                                                  index={index}
                                                />
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        ) : (
                                          ""
                                        )}

                                        {/* <RiDeleteBinLine/> */}
                                      </div>
                                    </div>
                                  </Card.Body>
                                ))}
                            </div>
                          </Accordion.Collapse>
                        </Card>
                      </div>
                    </div>
                  </Accordion>
                ))
              ) : (
                <h3 className="text-center mt-7">No Data Found</h3>
              )}
            </div>
            <div
              style={{ marginTop: -66 }}
              className="col-lg-4 col-xxl-4 col-md-4"
            >
              <div className="row userCardquri">
                <div className="col">
                  <div
                    className="d-flex rounded-top"
                    style={{
                      height: "65px",
                      minHeight: "65px",
                      background: "#1e1e2d",
                    }}
                  >
                  {myCurrentUser?.employment_end_date  ? '' :       <div
                      style={{
                        position: "absolute",
                        top: "20px",
                        right: "35px",
                      }}
                    >
                 {PremissionChecker(
                                          user,
                                          "leave_application.edit_remaining_leaves"
                                        ) &&      <div
                        className=""
                        onClick={() => openEditModal()}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <FaRegEdit
                          style={{ color: "white", fontSize: "1.5rem" }}
                        />
                      </div>}
                    </div>}
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"></div>

                      <div>
                        <div className="font-weight-bolder font-size-h5 text-light">
                        {myCurrentUser?.employment_end_date ? 'Leaver Holiday Details' :  'Holiday Details'}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-spacer bg-white rounded-bottom flex-grow-2">
                    <div className="m-0">
                      <div className="col px-8 py-2 rolequie">
                        <div className="row">
                          <div className="col-md-10 font-size-h5 font-weight-bold">
                            Carried Forward
                          </div>
                          <div className="col-md-2 font-size-h5 font-weight-bold text-right">
                            {remaingLeaves?.rollback_leaves || 0}
                          </div>
                        </div>
                      </div>

                      <div className="col px-8 py-2 rolequie">
                        <div className="row">
                          <div className="col-md-10 font-size-h5 font-weight-bold">
                            Basic Holiday Entitlement
                          </div>
                          <div className="col-md-2 font-size-h5 font-weight-bold text-right">
                            {remaingLeaves?.hard_coded_total || 0}
                          </div>
                        </div>
                      </div>

                      <div className="col px-8 py-2 rolequie">
                        <div className="row">
                          <div className="col-md-10 font-size-h5 font-weight-bold">
                            Manual Adjustment
                          </div>
                          <div className="col-md-2 font-size-h5 font-weight-bold text-right">
                            {remaingLeaves?.adjustment_leaves || 0}
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="col px-8 py-2 rolequie">
                        <div className="row">
                          <div className="col-md-10 font-size-h5 font-weight-bolder">
                            Total Holidays
                          </div>
                          <div className="col-md-2 font-size-h5 font-weight-bolder text-right">
                            {remaingLeaves?.total_leaves || 0}
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="col px-8 py-2 rolequie">
                        <div className="row">
                          <div className="col-md-10 font-size-h5 font-weight-bold">
                            Used Holidays
                          </div>
                          <div className="col-md-2 font-size-h5 font-weight-bold text-right">
                            {remaingLeaves?.used_leaves || 0}
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className="col px-8 py-2 rolequie">
                        <div className="row">
                          <div className="col-md-10 font-size-h5 font-weight-bolder">
                            Remaining Holidays
                          </div>
                          <div className="col-md-2 font-size-h5 font-weight-bolder text-right">
                            {remaingLeaves?.remaining_leaves || 0}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row userCardquri mt-5">
                <div className="col">
                  <div
                    className="d-flex rounded-top"
                    style={{
                      height: "65px",
                      minHeight: "65px",
                      background: "#1e1e2d",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"></div>
                      <div>
                        <div className="font-weight-bolder font-size-h5 text-light">
                          {myCurrentUser?.employment_end_date ? 'Leaver Detail' : 'Summary'}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-spacer bg-white rounded-bottom flex-grow-2">
                    <div className="m-0">
                      <div className="col px-8 py-0 rolequie">
                        <div className="font-size-sm text-muted font-weight-bold">
                          Name
                        </div>
                        <div className="font-size-h5 font-weight-bolder">
                          {myCurrentUser?.first_name} {myCurrentUser?.last_name}
                        </div>
                      </div>
                      <div className="col px-8 py-2 rolequie">
                        <div className="row">
                          {myCurrentUser?.employment_end_date && <div className="col-md-6">
                          <div className="font-size-sm text-muted font-weight-bold">
                        Joined At
                        </div>
                        <div className="font-size-h5 font-weight-bolder">
                          {myCurrentUser?.employment_start_date}
                        </div>
                          </div>}
                          <div className="col-md-6">
                          <div className="font-size-sm text-muted font-weight-bold">
                        {myCurrentUser?.employment_end_date ? 'Leaving Date' : 'Joined At'}
                        </div>
                        <div className="font-size-h5 font-weight-bolder">
                          {myCurrentUser?.employment_end_date ? myCurrentUser?.employment_end_date : dateConverter(myCurrentUser?.employment_start_date)}
                        </div>
                          </div>

                        </div>
                        {/* <div className="font-size-sm text-muted font-weight-bold">
                        {myCurrentUser?.employment_end_date ? 'Leaving Date' : 'Joined At'}
                        </div>
                        <div className="font-size-h5 font-weight-bolder">
                          {myCurrentUser?.employment_end_date ? ymdToDmy(myCurrentUser?.employment_end_date) : ymdToDmy(myCurrentUser?.employment_start_date)}
                        </div> */}
                      </div>
                      {myCurrentUser?.employment_end_date &&  <div className="col px-8 py-2 rolequie">
                        <div className="font-size-sm text-muted font-weight-bold">
                        Holiday Leaver Entitlement
                        </div>
                        <div className="font-size-h5 font-weight-bolder">
                          {myCurrentUser?.holiday_leaver_entitlement}
                        </div>
                      </div>}
                    {myCurrentUser?.employment_end_date &&  <div className="col px-8 py-2 rolequie">
                        <div className="font-size-sm text-muted font-weight-bold">
                        Leaver Remaining Leaves
                        </div>
                        <div className="font-size-h5 font-weight-bolder">
                          {myCurrentUser?.leaver_remaining_leaves}
                        </div>
                      </div>}
                      {myCurrentUser?.employment_end_date ? '' :
                      <>
                      <hr />
                   
                           {/* <div className="col px-8 py-2 rolequie">
                              <div className="row">
                                <div className="col-md-10 font-size-h5 font-weight-bold">
                                Approved 
                                </div>
                                <div className="col-md-2 font-size-h5 font-weight-bold text-right">
                                  {getApprovedLeaves(dataSearch2[0]?.data)}
                                </div>
                              </div>
                            </div> */}
                               {dataSearch2[0]?.data?.length > 0
                        ? dataSearch2[0]?.data.map((main, index) => (
                            <div className="col px-8 py-2 rolequie">
                              <div className="row">
                                <div className="col-md-10 font-size-h5 font-weight-bold">
                                  {main.name}
                                </div>
                                <div className="col-md-2 font-size-h5 font-weight-bold text-right">
                                  {getApprovedLeaves(main?.allApplications)}
                                </div>
                              </div>
                            </div>
                          ))
                        : ""}
                        </>
}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Modal
        show={editModel}
        onHide={() => {
          getLeaves(yearFilter.value, myCurrentUser.id);
          setEditModel(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Remaining Leaves</Modal.Title>
          {getLeavesLoading ? (
            <div style={{ position: "absolute", right: "20px" }}>
              <Spinner animation="border" />
            </div>
          ) : (
            <button
              onClick={() => {
                getLeaves2(yearFilter.value, myCurrentUser.id);
              }}
              style={{ position: "absolute", right: "20px" }}
              className="btn btn-primary"
            >
              Reset
            </button>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mt-3">
              <label>
                <b>Full Time Staff Entitlement</b>
              </label>
              <input
                value={postStaffEntitlement}
                onChange={(e) => setPostStaffEntitlement(e.target.value)}
                className="form-control"
                placeholder="Enter full time staff entitlement"
              />
            </div>
            <div className="col-md-12 mt-3">
              <label>
                <b>Weekly Hours</b>
              </label>
              <input
                value={postWeeklyHours}
                onChange={(e) => setPostWeeklyHours(e.target.value)}
                className="form-control"
                placeholder="Enter weekly hours"
              />
            </div>

            <div className="col-md-12 mt-3">
              <label>
                <b>Carried Forward</b>
              </label>
              <input
                value={postCarried}
                onChange={(e) => setPostCarried(e.target.value)}
                className="form-control"
                placeholder="Enter carried forward"
              />
            </div>
            <div className="col-md-12 mt-3">
              <label>
                <b>Manual Adjustment</b>
              </label>
              <input
                value={postAdjustment}
                onChange={(e) => setPostAdjustment(e.target.value)}
                className="form-control"
                placeholder="Enter manual adjustment"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {postLoading ? (
            <div className="d-flex align-items-center justify-content-center mr-4">
              <Spinner animation="border" />
            </div>
          ) : (
            <button
              onClick={() => updateHolidays()}
              className="btn btn-primary"
            >
              Update
            </button>
          )}
          <button
            onClick={() => {
              getLeaves(yearFilter.value, myCurrentUser.id);
              setEditModel(false);
            }}
            className="btn btn-danger"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewHolidays;
