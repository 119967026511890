// /**
//  * High level router.
//  *
//  * Note: It's recommended to compose related routes in internal router
//  * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
//  */

import React, { useEffect, useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
// import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { useDispatch, useSelector, connect, shallowEqual } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import { baseUrl } from "../services/config";


function Routes() {


  const dispatch = useDispatch();
  const [authorized, setauthorized] = useState()


  const { isAuthenticated } = useSelector(state => state.user);
  const { user } = useSelector(state => state.user);
  console.log(isAuthenticated, "isAuthorized=>")


  const authToken = localStorage.getItem("userToken")


  // useEffect(() => {
  //   console.log(isAuthenticated, "isAuthorized=>")
  //   setauthorized(isAuthenticated)
  //   if (
  //     performance.navigation.type == performance.navigation.TYPE_RELOAD &&
  //     localStorage.getItem("userToken")
  //   ) {
  //     dispatch({
  //       type: "ADD_USER",
  //       payload: {
  //         isAuthenticated: true,
  //         authtoken: localStorage.getItem("userToken"),
  //         user: JSON.parse(localStorage.getItem("userDetails")),
  //       },
  //     });
  //   }
  // }, []);



  useEffect(() => {
    dispatch({
      type: 'NETWORK_CHECK',
      payload: {
        networkStatus: navigator.onLine
      }
    })

  }, [navigator.onLine])



  // useEffect(() => {

  //   getUserDetails()

  // }, [])



  // const getUserDetails = () => {
  //   fetch(`${baseUrl}/mgmt/user-profile`, {
  //     method: 'GET',
  //     headers: {
  //       Authorization: `Bearer ${authToken}`
  //     }
  //   }).then(res => res.json())
  //     .then((data) => {
  //       if (data.metadata.success) {
  //         dispatch({
  //           type: "ADD_USER",
  //           payload: {
  //             // isAuthenticated: true,
  //             // authtoken: user.payload.token,
  //             user: data.payload,
  //           },
  //         });
  //       }
  //     }).catch(err => {
  //       console.log(err)
  //     })
  // }







  return (
    <Switch>
      {!localStorage.getItem("userToken") || authorized ? (
        //Render auth page when user at `/auth` and not authorized.
        <Route>
          <AuthPage />
        </Route>
      ) : localStorage.getItem("userToken") || authorized ? (
        <Redirect from="/auth" to="/dashboard" />
      ) : null}

      <Route path="/error" component={ErrorsPage} />

      {localStorage.getItem("userToken") || authorized ? (
        <Layout>
          {
            user && 
              <BasePage />
              
            

          }
        </Layout>
      ) : (
        <Redirect to="/auth/login" />
      )}
    </Switch>
  );
}
export default injectIntl(connect(null, auth.actions)(Routes));

